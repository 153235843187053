const COMPANY_CERTIFICATE_TYPE =  "cert_of_all"
const SHIPPING_METHOD = {
  normal: 'normal',
  express: 'express'
}
export const COMPANY_REQUEST = {
  id: null,
  // project_id: 0,
  company_certificate_type: COMPANY_CERTIFICATE_TYPE,
  shipping_method: SHIPPING_METHOD.normal,
  certificate_num: 0,
  normal_pdf_num: 0,
  express_pdf_num: 0,
  payment_method: 'stripe',
  address_id: null,
  address: {
    zip_code: null,
    address1: null,
    address2: null,
    building_name: null,
    recipient_name: null
  },
}

export const SEAL_CERTIFICATE = {
  id: null,
  certificate_num: 0,
  payment_method: 'stripe',
  payment_amount: 0.0,
  address: {
    zip_code: null,
    address1: null,
    address2: null,
    building_name: null,
    recipient_name: null
  },
}

export const DIGITAL_CERTIFICATE = {
  id: null,
  company_id: null,
  submit_person_id: null,
  certificate_valid_time: 3,
  summit_type: 'self',
  summit_type_price: null,
  agent_username: null,
  agent_address: null,
  is_prepare_stamp: 1,
  stamp_price: null,
  serial_number: null,
  password: null,
  certificate_file_path: null,
  payment_method: 'stripe',
  address: {
    zip_code: null,
    address1: null,
    address2: null,
    building_name: null,
    recipient_name: null
  },
}














