export async function index() {
  const {data} = await this.$adminRepositories.projects.index();
  return data;
}

export async function deleteProjectById({commit}, payload) {
  const {data} = await this.$adminRepositories.projects.delete(payload.id);
  await commit('removeItem', payload);
  return data;
}

export async function findProjectById(_, payload) {
  const {data} = await this.$adminRepositories.projects.show(payload);
  return data;
}

export async function getAllConfigs() {
  const {data} = await this.$adminRepositories.projects.getAllConfigs()
  return data
}

export async function updateSikulixPayment({}, id) {
  const {data} = await this.$adminRepositories.projects.updateSikulixPayment(id)
  return data
}
