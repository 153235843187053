export async function getList({commit}) {
  const response = await this.$frontendRepositories.historyAddresses.index();
  const {data} = response;
  commit('setList', data);
  return response;
}

export async function destroy(_, id) {
  return await this.$frontendRepositories.historyAddresses.destroy(id);
}
