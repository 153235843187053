import { FRONTEND_VERSION_API } from "@/contants/api";
const projectRepository = `${FRONTEND_VERSION_API}/projects`;

export default $axios => ({
  index() {
    return $axios.get(projectRepository);
  },
  show(hash_id) {
    return $axios.get(`${projectRepository}/${hash_id}`);
  },
  destroy(hash_id) {
    return $axios.delete(`${projectRepository}/${hash_id}`);
  },
  reoderProject(id) {
    return $axios.get(`${projectRepository}/re-oder-project/${id}`);
  },
  getAllConfigs() {
    return $axios.get(`${projectRepository}/get-all-configs`);
  },
  updateReceiptPayment(payload) {
    return $axios.put(
      `${projectRepository}/update-receipt-payment/${payload.hash_id}`,
      payload
    );
  },
  getSubmissionAddress(address) {
    return $axios.post(`${projectRepository}/get-submission-address`, {
      address: address
    });
  },
});
