import { FRONTEND_VERSION_API } from "@/contants/api";
const historyAddressRepository = `${FRONTEND_VERSION_API}/history-addresses`;

export default $axios => ({
  index() {
    return $axios.get(historyAddressRepository);
  },
  destroy(id) {
    return $axios.delete(`${historyAddressRepository}/${id}`);
  }
});
